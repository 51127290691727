import React from 'react';
import {
  TextField,
} from '@mui/material';
import { PropTypes } from 'prop-types';

export function FieldText({
  field, onChange, indexField, indexGroup, indexFormulario,
}) {
  return (
    <TextField
      fullWidth
      size="small"
      label={field.label}
      value={field.value}
      error={field.required && (field.value === '' || field.value === undefined || field.value === null)}
      helperText={field.required && (field.value === '' || field.value === undefined || field.value === null) ? '* Campo Obligatorio' : ''}
      onChange={(e) => { onChange(indexField, e.target.value, indexGroup, indexFormulario); }}
      sx={{ mt: 2 }}
      inputProps={{
        maxLength: field.length && Number(field.length),
        // readOnly: field.hasOwnProperty('editable') ? !field.editable : false,
        disabled: field.hasOwnProperty('editable') ? !field.editable : false,
      }}

    />

  );
}

FieldText.propTypes = {
  field: PropTypes.objectOf.isRequired,
  onChange: PropTypes.func.isRequired,
  indexField: PropTypes.number.isRequired,
  indexGroup: PropTypes.number.isRequired,
  indexFormulario: PropTypes.number.isRequired,

};

export default FieldText;
