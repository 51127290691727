import React from 'react';
import {
  Box,
  Button,
  FormHelperText,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export function FieldFile({
  field, onChange, indexField, indexGroup, indexFormulario,
}) {
  return (
    <Box sx={{ mb: 0, mt: 2 }}>
      <Button
        fullWidth
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        {field.label}
        {field.value && ` : ${field.file ? field.file.name : field.value}`}
        <VisuallyHiddenInput onChange={(e) => onChange(indexField, e, indexGroup, indexFormulario)} type="file" />
      </Button>
      {field.required && (field.value === '' || field.value === undefined || field.value === null) && (
        <FormHelperText error>{`${field.required ? '* Campo Obligatorio' : ''}`}</FormHelperText>
      )}
    </Box>
  );
}

FieldFile.propTypes = {
  field: PropTypes.objectOf.isRequired,
  onChange: PropTypes.func.isRequired,
  indexField: PropTypes.number.isRequired,
  indexGroup: PropTypes.number.isRequired,
  indexFormulario: PropTypes.number.isRequired,
};

export default FieldFile;
