import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Divider,
  Box,
  ButtonGroup,
  Alert,
  Card,
  CardHeader,
  CardActions,
  Grid,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import {
  red,
} from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import CreateForms from '../../organismos/forms';
import { cardsActionsSaga } from '../../../../infrastructure/services/cards';

function CarActivity({
  artefactoMostar,
  columIdMostar,
  artefactoMostarData,
  handleCloseMostrar,
  idWorkflowSelected,
  cardSelected,
  idActivitySelected,
}) {
  const dispatch = useDispatch();
  const [newFields, setNewFields] = useState(null);
  const [enabledForm, setEnabledForm] = useState(null);
  const [formSelecte, setFormSelecte] = useState(null);

  const findData = () => {
    if (artefactoMostarData) {
      const query = `${artefactoMostarData}/?columnId=${columIdMostar}`;
      dispatch(cardsActionsSaga.activeCardsGetDetail({ query }));
    }
  };

  useEffect(() => {
    findData();
  }, [artefactoMostarData]);

  const { cardDetail, result, connection } = useSelector((store) => store.bazooit.cards.cardDetail);
  const { cardHasBeenUpdated } = useSelector((store) => store.bazooit.cards);

  useEffect(() => {
    if (!connection && cardDetail !== null) {
      const formStateSelect = cardDetail.filter((item) => item.id === idActivitySelected);
      setFormSelecte(formStateSelect);
    }
  }, [cardDetail, connection]);

  const rojo = red[500];

  const getFields = (get) => {
    console.log(get);
    setNewFields(get);
  };

  const getEnabledForm = (flag) => {
    setEnabledForm(flag);
  };

  const handlerActualizar = () => {
    let data = {};
    console.log(newFields);
    for (const x in newFields) {
      for (const a in newFields[x].groups) {
        for (const b in newFields[x].groups[a].fields) {
          if (newFields[x].groups[a].fields[b].kind !== 'file') {
            data = { ...data, [newFields[x].groups[a].fields[b].id]: newFields[x].groups[a].fields[b].value };
          } else {
            data = { ...data, [newFields[x].groups[a].fields[b].id]: newFields[x].groups[a].fields[b].file };
          }
        }
      }
    }

    console.log(data);

    dispatch(cardsActionsSaga.activeCardsUpdateData(
      {
        cards: {
          workflowId: idWorkflowSelected,
          columnId: columIdMostar,
          ...data,
        },
        idCard: artefactoMostarData,
      },
    ));
    handleCloseMostrar();
  };

  useEffect(() => {
    if (cardHasBeenUpdated.result) { handlerActualizar(); }
  }, [cardHasBeenUpdated]);

  return (
    <Dialog
      disableEscapeKeyDown
      open={artefactoMostar}
      onClose={handleCloseMostrar}
      fullWidth="md"
      maxWidth="md"
    >
      <DialogTitle sx={{ lineHeight: 1.2 }}>
        {!cardHasBeenUpdated.checking && (
          <CardHeader
            avatar={(
              <Avatar
                sx={{
                  bgcolor: rojo, width: 30, height: 30, fontSize: '15px',
                }}
                aria-label="recipe"
              >
                {cardSelected.visibleFieldsValues[0][0].toUpperCase()}
              </Avatar>
            )}

            title={formSelecte !== null && formSelecte[0].name}
            sx={{ p: 0, pb: 0 }}
          />
        )}
        {
          cardHasBeenUpdated.checking
          && !cardHasBeenUpdated.result
          && !cardHasBeenUpdated.connection && (
            <Typography sx={{ pb: 0, mb: 0 }} variant="h5" component="p">
              {result ? 'Confirmar cambios' : <Skeleton variant="rectangular" width={300} height={30} />}
            </Typography>
          )
        }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => handleCloseMostrar()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      {' '}
      {cardHasBeenUpdated.checking && !cardHasBeenUpdated.result
        ? (
          <>
            <DialogContent sx={{ pl: 0, pr: 0 }}>
              <Box sx={{ pl: 4, pr: 4 }}>
                <Alert severity="error">{cardHasBeenUpdated.message}</Alert>
              </Box>
            </DialogContent>
            <CardActions>
              <ButtonGroup sx={{ pt: 0, zIndex: 5 }} fullWidth variant="contained">
                <Button onClick={(e) => { handlerActualizar(e); }}>
                  Si, Confirmar
                </Button>
                <Button onClick={(e) => { handleCloseMostrar(e); }}>
                  Descartar Cambios
                </Button>
              </ButtonGroup>
            </CardActions>
          </>
        ) : (
          <DialogContent sx={{ p: 0, backgroundColor: '#ededed' }}>
            <Grid sx={{ flexGrow: 1, p: 0.2, pr: 0.5 }} container>
              <Grid xs={12} sm={12} md={12} sx={{ p: 0.5 }}>
                <Card sx={{ mb: 0 }}>
                  {formSelecte !== null && (
                  <CreateForms
                    fields={cardDetail}
                    getFields={getFields}
                    getEnabledForm={getEnabledForm}
                    idGroupShow={idActivitySelected}
                  />
                  )}
                  <CardActions>
                    <ButtonGroup sx={{ pt: 0, zIndex: 5 }} fullWidth variant="contained">
                      <Button disabled={!enabledForm} onClick={(e) => { handlerActualizar(e); }}>
                        Si, Confirmar
                      </Button>
                      <Button onClick={(e) => { handleCloseMostrar(e); }}>
                        Descartar Cambios
                      </Button>
                    </ButtonGroup>
                  </CardActions>
                </Card>

              </Grid>

            </Grid>
          </DialogContent>
        )}
    </Dialog>

  );
}

export default CarActivity;
