import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination } from 'swiper/modules';

import {
  IconButton,
  Divider,
  Paper,
  Box,
  Grid,
} from '@mui/material';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Badge from '@mui/material/Badge';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CachedIcon from '@mui/icons-material/Cached';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import moment from 'moment';
import Fab from '@mui/material/Fab';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { cardsActionsSaga } from '../../../../infrastructure/services/cards';
import { cardsActions } from '../../../../application/actions/cards';
import Cards from '../../molecula/cards';
import ArtefactoMostrar from '../../molecula/ArtefactoMostrar';
import ArtefactoTimeLive from '../../molecula/ArtefactoTimeLive';
import CreateNewCards from '../createNewCards';
import { TemplatesService } from '../../../../infrastructure/services/template';
import ComentsView from '../../molecula/ComentsView';
import { comentsActions } from '../../../../application/actions/coments';
import CarActivity from '../../molecula/CardActivity';

import './index.css';

const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};

function WorkFlow() {
  const dispatch = useDispatch();

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });

  /* NUEVOS METODOS */

  /* VARIABLES DE WORKFLOW */
  const [newWorkflow, setNewWorkflow] = useState({
    id: '', name: '', description: '', columns: [],
  });

  /* VARIABLES DE application */
  const {
    firstName, segundoNombre, lastName, apellidoMaterno,
  } = useSelector((store) => store.bazooit.session);
  const { workflows } = useSelector((store) => store.bazooit.workflowStorage);
  const {
    nameWorkflowSelected, idWorkflowSelected, descriptionWorkflowSelected,
  } = useSelector((store) => store.bazooit.storageBrowser);
  const { cards, updateCards } = useSelector((store) => store.bazooit.cards);
  const [open, setOpen] = React.useState(false);
  const [posisicoUsuario, setPosicionUsaurio] = useState({ valor: 1 });

  const [artefactoMostar, setAretfactoMostar] = useState(false);
  const [artefactoMostarData, setAretfactoMostarData] = useState({});
  const [columIdMostar, setColumIdMostar] = useState({});

  const [artefactoTimeLive, setAretfactoTimeLive] = useState(false);
  const [artefactoTimeLiveData, setAretfactoTimeLiveData] = useState({});

  const [comentsViewShow, setComentsViewShow] = useState(false);
  const [cardSelected, setCardSelected] = useState(null);

  const [carActivity, setCarActivity] = useState(false);
  const [idActivitySelected, setIdActivitySelected] = useState(null);

  /** CARGA INICIAL FLUJO */
  useEffect(() => {
    workflows.length > 0 && handleRefrescar();
  }, [idWorkflowSelected, workflows]);

  useEffect(() => {
    newWorkflow.columns.length > 0 && dispatch(cardsActionsSaga.activeFieldsUpdate({ id: newWorkflow.columns[0].id }));
  }, [newWorkflow]);

  const handleRefrescar = () => {
    if (idWorkflowSelected) {
      const query = `findByQuery?workflowId=${idWorkflowSelected}`;
      dispatch(cardsActionsSaga.activeCardsGet({ query }));
      dispatch(cardsActions.cardsUpdate({ result: false }));
    }
    const flujo = workflows.filter((x) => x.id === idWorkflowSelected);
    flujo.length > 0 && setNewWorkflow(flujo[0]);
  };

  useEffect(() => {
    if (updateCards.result) {
      handleRefrescar();
    }
  }, [updateCards]);

  /* VARIABLES DE COMPONENTES */

  const handleClose = () => {
    setOpen(false);
  };

  const capitalizarPrimeraLetra = (str) => {
    if (str === null || str === '') {
      return '';
    }
    return str;
  };

  const createCaso = () => {
    setOpen(true);
  };

  const handleCambioEstado = (cardsIn, columnId) => {
    const changeColumnsCard = { columnId, id: cardsIn.id };
    dispatch(cardsActionsSaga.activeCardsUpdate({ changeColumnsCard }));
  };

  const retornarCntidadArtefactosPorEtapa = (columnId) => {
    let cantidad = 0;
    cantidad = cards.cards.rows
      && cards.cards.rows[columnId] ? cards.cards.rows[columnId].length : 0;
    return cantidad;
  };

  // BUSCAR
  const [buscar, setBuscar] = useState('');
  const handleBuscar = (nuevaBusqueda) => {
    setBuscar(nuevaBusqueda);
  };

  // MOSTRAR

  const handleCloseMostrar = () => {
    dispatch(comentsActions.getComentsActions({ coments: [] }));
    dispatch(cardsActions.cardsGetDetailReset());
    dispatch(cardsActions.cardHasBeenUpdated({
      message: '',
      success: false,
      connection: false,
      checking: false,
      error: false,
    }));

    const newMostrar = !artefactoMostar;
    setAretfactoMostar(newMostrar);
  };

  const handleVerMostrar = (cardsId, coulmId) => {
    setAretfactoMostarData(cardsId);
    setColumIdMostar(coulmId);
    handleCloseMostrar();
  };

  // ACTIVITY

  const handleCloseActivity = () => {
    dispatch(cardsActions.cardsGetDetailReset());
    /* dispatch(comentsActions.getComentsActions({ coments: [] }));
    dispatch(cardsActions.cardHasBeenUpdated({
      message: '',
      success: false,
      connection: false,
      checking: false,
      error: false,
    })); */

    const newCarActivity = !carActivity;
    setCarActivity(newCarActivity);
  };

  // TIME LIVE

  const handleCloseTimeLive = () => {
    const newMostrar = !artefactoTimeLive;
    setAretfactoTimeLive(newMostrar);
  };

  const handleVerTimeLive = (artefactoIn) => {
    // console.log(artefactoIn);
    setAretfactoTimeLiveData(artefactoIn);
    handleCloseTimeLive();
  };

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  // TEMPLETES
  const {
    get: getTemlete,
  } = useSelector((store) => store.bazooit.template);

  useEffect(() => {
    dispatch(TemplatesService.activeGetTemplate({ id: idWorkflowSelected }));
  }, [idWorkflowSelected]);

  const handlerLoadFileTemplete = (cardId, templateId, name) => {
    const generatePdf = true;
    dispatch(TemplatesService.activeDownloadTemplateFile({
      cardId, templateId, name, generatePdf,
    }));
  };

  const column = (itemEtapa, indexColumn) => {
    const BuildColumna = (
      <Box
        sx={{
          pl: 0.5,
          pr: 0.5,
          flex: 1,
          height: '100%',
          display: 'flex',
          width: width < 600 ? '100%' : 100 / newWorkflow.columns.length,
          flexDirection: 'column',

        }}

      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            p: 0, pb: 0, backgroundColor: '#ededed', borderTopLeftRadius: 5, borderTopRightRadius: 5,
          }}

        >
          <Typography sx={{ p: 0.8, pl: 2, fontWeight: 100 }}>
            {capitalizarPrimeraLetra(itemEtapa.name)}
          </Typography>
          <Typography sx={{ p: 0.8 }}>
            <Badge badgeContent={retornarCntidadArtefactosPorEtapa(itemEtapa.id)} color="primary">
              <ListAltIcon fontSize="small" color="action" />
            </Badge>
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: '#ededed', flex: 1, overflow: 'hidden', overflowY: 'auto', borderBottomLeftRadius: 5, borderBottomRightRadius: 5,
          }}
        >
          {
            newWorkflow && (
              <Cards
                idEtapa={itemEtapa.id}
                indexColumn={indexColumn}
                actividades={itemEtapa.activities}
                eventos={itemEtapa.eventos}
                handleActividad={handlersUniversalClicOpctioncards}
                handleCambioEstado={handleCambioEstado}
                etapas={newWorkflow.columns}
                buscar={buscar}
                handleVerMostrar={handleVerMostrar}
                handleVerTimeLive={handleVerTimeLive}
                templetes={getTemlete.data}
                handlerLoadFileTemplete={handlerLoadFileTemplete}

                handlresComentsViewShow={handlersUniversalClicOpctioncards}

              />
            )
          }
        </Box>
      </Box>

    );

    return BuildColumna;
  };

  const handlersUniversalClicOpctioncards = (card, typeAction, coulmId, idActividad) => {
    if (typeAction === 'coments') {
      const newState = !comentsViewShow;
      setComentsViewShow(newState);
      if (card !== undefined) {
        setCardSelected(card);
      }
    }

    if (typeAction === 'details') {
      setAretfactoMostarData(card.id);
      setColumIdMostar(coulmId);
      handleCloseMostrar();
      if (card !== undefined) {
        setCardSelected(card);
      }
    }

    if (typeAction === 'activiy') {
      setAretfactoMostarData(card.id);
      setColumIdMostar(coulmId);
      setIdActivitySelected(idActividad);
      handleCloseActivity();
      if (card !== undefined) {
        setCardSelected(card);
      }
    }
  };

  return (

    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        overflow: 'hidden',
        paddingBottom: 1,
        bottom: 0,
        top: 0,
        backgroundColor: '#989898',
      }}
      className="WorkFlow"
    >

      <Fab onClick={() => createCaso()} sx={fabStyle} color="primary" aria-label="add">
        <AddIcon />
      </Fab>

      {open && (
        <CreateNewCards
          handleClose={handleClose}
          handleRefrescar={handleRefrescar}
          open={open}
        />
      )}

      {artefactoMostar
        && (
          <ArtefactoMostrar
            artefactoMostar={artefactoMostar}
            columIdMostar={columIdMostar}
            artefactoMostarData={artefactoMostarData}
            handleCloseMostrar={handleCloseMostrar}
            idWorkflowSelected={idWorkflowSelected}
            cardSelected={cardSelected}

          />
        )}

      {carActivity && (
        <CarActivity
          artefactoMostar={carActivity}
          columIdMostar={columIdMostar}
          artefactoMostarData={artefactoMostarData}
          handleCloseMostrar={handleCloseActivity}
          idWorkflowSelected={idWorkflowSelected}
          cardSelected={cardSelected}
          idActivitySelected={idActivitySelected}
        />
      )}

      {artefactoTimeLive
        && (
          <ArtefactoTimeLive
            artefactoMostar={artefactoTimeLive}
            card={artefactoTimeLiveData}
            handleCloseMostrar={handleCloseTimeLive}
          />
        )}

      {comentsViewShow
        && (
          <ComentsView
            handlerShow={handlersUniversalClicOpctioncards}
            show={comentsViewShow}
            cardSelected={cardSelected}
            integrate={false}
          />
        )}

      <Stack sx={{ flexGrow: 1 }}>
        <ThemeProvider theme={darkTheme}>
          <AppBar position="static" color="primary">
            <Grid className="barWorkflow" container spacing={2} sx={{ p: 0 }}>
              <Grid className="pading-top-titulo" sx={{ p: 0 }} item xs={12} sm={6} md={8}>
                <Grid container sx={{ p: 0 }}>
                  <Grid sx={{ p: 0, pt: 1, pl: 2 }} item xs={12}>
                    <Typography sx={{ p: 0, fontSize: 14, lineHeight: 0 }} component="strong">
                      <strong>{nameWorkflowSelected}</strong>
                    </Typography>
                    <Typography
                      className="mobile-none"
                      sx={{
                        p: 0, fontSize: 11, lineHeight: 0.8, fontWeight: 100, color: '#d7d7d7',
                      }}
                    >
                      <small>{descriptionWorkflowSelected}</small>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className="search" item xs={12} sm={6} md={4} textAlign="end">
                <Grid container spacing={0} sx={{ p: 0.5 }}>
                  <Paper
                    className="pading-top-titulo"
                    component="form"
                    sx={{
                      p: 0, display: 'flex', alignItems: 'right', width: '100%',
                    }}
                  >
                    {posisicoUsuario.valor === 1 && (
                      <>

                        <InputBase
                          sx={{
                            ml: 1, flex: 1, fontSize: 13, p: 0,
                          }}
                          value={buscar}
                          placeholder={`Buscar en ${nameWorkflowSelected}`}
                          inputProps={{ 'aria-label': 'search...', p: 0 }}
                          onChange={(e) => handleBuscar(e.target.value)}
                          size="small"
                        />
                        <IconButton size="small" type="button" sx={{ p: '10px' }} aria-label="buscar">
                          <SearchIcon fontSize="small" />
                        </IconButton>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                      </>
                    )}
                    <IconButton onClick={() => handleRefrescar()} color="" sx={{ p: '10px' }} aria-label="directions">
                      <CachedIcon fontSize="small" />
                    </IconButton>
                  </Paper>
                </Grid>

              </Grid>
            </Grid>
          </AppBar>
        </ThemeProvider>
      </Stack>
      {/* KANBAN */}
      {posisicoUsuario.valor === 1 && (
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#ccc',
          padding: 1,
          pl: 0.5,
          pr: 0.5,
          height: (height - (width < 600 ? 114 : 114)),
        }}
        >
          <br />

          {width < 600 ? (
            <Swiper
              effect="coverflow"
              grabCursor
              centeredSlides
              slidesPerView="auto"
              coverflowEffect={{
                rotate: 10,
                stretch: 10,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              pagination
              modules={[EffectCoverflow, Pagination]}
              className="mySwiper"
            >
              {newWorkflow.columns.map((itemEtapa, indexColumn) => (
                <SwiperSlide>{column(itemEtapa, indexColumn)}</SwiperSlide>

              ))}
            </Swiper>
          )
            : newWorkflow.columns.map((itemEtapa, indexColumn) => (
              column(itemEtapa, indexColumn)
            ))}
        </Box>
      )}

    </Box>

  );
}

export default WorkFlow;
