import { createSlice } from '@reduxjs/toolkit';
import initState from '../../domain/entities/cards';

function cardsGet(state, action) {
  action.payload.cards && (state.cards.cards = action.payload.cards);
  action.payload.result && (state.cards.result = action.payload.result);
  action.payload.connection && (state.cards.connection = action.payload.connection);
  action.payload.error && (state.cards.error = action.payload.error);
}

function cardsCreate(state, action) {
  state.createCards.result = action.payload.result ? action.payload.result : false;
  state.createCards.connection = action.payload.connection ? action.payload.connection : false;
  state.createCards.error = action.payload.error ? action.payload.error : false;
}

function cardsUpdate(state, action) {
  state.updateCards.result = action.payload.result ? action.payload.result : false;
  state.updateCards.connection = action.payload.connection ? action.payload.connection : false;
  state.updateCards.error = action.payload.error ? action.payload.error : false;
}

function fieldsUpdate(state, action) {
  action.payload.fields && (state.fieldsByColumnId.fields = action.payload.fields);
  action.payload.result && (state.fieldsByColumnId.result = action.payload.result);
  action.payload.connection && (state.fieldsByColumnId.connection = action.payload.connection);
  action.payload.error && (state.fieldsByColumnId.error = action.payload.error);
}

function cardsGetDetail(state, action) {
  action.payload.hasOwnProperty('cardDetail') && (state.cardDetail.cardDetail = action.payload.cardDetail);
  action.payload.hasOwnProperty('result') && (state.cardDetail.result = action.payload.result);
  action.payload.hasOwnProperty('connection') && (state.cardDetail.connection = action.payload.connection);
  action.payload.hasOwnProperty('error') && (state.cardDetail.error = action.payload.error);
}

function cardsGetDetailReset(state, action) {
  state.cardDetail.cardDetail = null;
  state.cardDetail.result = false;
  state.cardDetail.connection = false;
  state.cardDetail.error = false;
}

function cardHasBeenUpdated(state, action) {
  action.payload.hasOwnProperty('message') && (state.cardHasBeenUpdated.message = action.payload.message);
  action.payload.hasOwnProperty('success') && (state.cardHasBeenUpdated.result = action.payload.success);
  action.payload.hasOwnProperty('connection') && (state.cardHasBeenUpdated.connection = action.payload.connection);
  action.payload.hasOwnProperty('error') && (state.cardHasBeenUpdated.error = action.payload.error);
  action.payload.hasOwnProperty('checking') && (state.cardHasBeenUpdated.checking = action.payload.checking);
}

/**
 * Conexión
 */
const cardsReducer = createSlice({
  name: 'cards',
  initialState: initState,
  reducers: {
    cardsGet,
    cardsCreate,
    cardsUpdate,
    fieldsUpdate,
    cardsGetDetail,
    cardsGetDetailReset,
    cardHasBeenUpdated,
  },
});

export const cardsActions = {
  ...cardsReducer.actions,
};

export default cardsReducer.reducer;
