import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  FormControl,
  Divider,
  Box,
  CardContent,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import FeedIcon from '@mui/icons-material/Feed';
import {
  FieldPhone,
  FieldEmail,
  FieldText,
  FieldtextArea,
  FieldNumber,
  FieldList,
  FieldFile,
  FieldDateTime,
  FieldListImputIncrement,
} from './fields';

function CreateForms({
  fields,
  getFields,
  getEnabledForm,
  idGroupShow,
}) {
  const [tiposCampos] = useState([
    'grupo',
    'text',
    'textarea',
    'list',
    'file',
    'phone',
    'email',
    'number',
    'date',
    'time',
    'rangeDate',
    'rangeTime',
    'listInput',
  ]);

  const [formulario, setFormulario] = useState([]);

  useEffect(() => {
    setFormulario(JSON.parse(JSON.stringify(fields)));
  }, []);

  const validateForm = () => {
    let enable = true;
    let formularioValidar = formulario[0];
    if (idGroupShow !== 0) {
      formularioValidar = formulario.filter((item) => item.id === idGroupShow)[0];
    }
    if (formulario.length > 0) {
      for (const g in formularioValidar.groups) {
        for (const f in formularioValidar.groups[g].fields) {
          if (formularioValidar.groups[g].fields[f].kind !== 'list' && formularioValidar.groups[g].fields[f].required && (formularioValidar.groups[g].fields[f].value === '' || formularioValidar.groups[g].fields[f].value === undefined || formularioValidar.groups[g].fields[f].value === null)) {
            enable = false;
            break;
          }

          if (formularioValidar.groups[g].fields[f].kind === 'list' && formularioValidar.groups[g].fields[f].required && formularioValidar.groups[g].fields[f].value && (formularioValidar.groups[g].fields[f].required && formularioValidar.groups[g].fields[f].value.length <= 0 || formularioValidar.groups[g].fields[f].value === null)) {
            enable = false;
            break;
          }
        }
        if (!enable) break;
      }
    }
    getEnabledForm(enable);
  };

  useEffect(() => {
    getFields(formulario);
    validateForm();
  }, [formulario]);

  const updateFields = (index, newValue, indexGroup, indexFormulario) => {
    const newGroups = [...formulario];
    newGroups[indexFormulario].groups[indexGroup].fields[index].value = newValue;
    setFormulario(newGroups);
  };

  const handleUpload = (index, newValue, indexGroup, indexFormulario) => {
    const newGroups = [...formulario];
    const file = newValue.target.files[0];
    newGroups[indexFormulario].groups[indexGroup].fields[index].value = newValue;
    newGroups[indexFormulario].groups[indexGroup].fields[index].file = file;
    setFormulario(newGroups);
  };

  const buildFields = (fieldGroup, indexGroup, indexFormulario) => {
    const fieldsLocal = fieldGroup.map((field, indexField) => (
      <Box key={field.id}>
        {/* CAMPO TEXTO */}
        {field.kind === tiposCampos[1] && (
          <FieldText
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
            indexFormulario={indexFormulario}
          />
        )}
        {/* CAMPO AREA DE TEXTO */}
        {field.kind === tiposCampos[2] && (
          <FieldtextArea
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
            indexFormulario={indexFormulario}
          />
        )}
        {/* CAMPO DE LISTA */}
        {field.kind === tiposCampos[3] && (
          <FieldList
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
            indexFormulario={indexFormulario}
          />
        )}
        {/* CAMPO FILE */}
        {field.kind === tiposCampos[4] && (
          <FieldFile
            field={field}
            onChange={handleUpload}
            indexField={indexField}
            indexGroup={indexGroup}
            indexFormulario={indexFormulario}
          />

        )}
        {/* CAMPO phone */}
        {field.kind === tiposCampos[5] && (
          <FieldPhone
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
            indexFormulario={indexFormulario}
          />
        )}
        {/* CAMPO email */}
        {field.kind === tiposCampos[6] && (
          <FieldEmail
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
            indexFormulario={indexFormulario}
          />
        )}
        {/* CAMPO NUMERO */}
        {field.kind === tiposCampos[7] && (
          <FieldNumber
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
            indexFormulario={indexFormulario}
          />
        )}
        {/* DATE */}
        {field.kind === tiposCampos[8] && (
          <FieldDateTime
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
            indexFormulario={indexFormulario}
          />
        )}
        {/* FIEDS LIST IMPUT */}
        {field.kind === tiposCampos[12] && (
          <FieldListImputIncrement
            field={field}
            onChange={updateFields}
            indexField={indexField}
            indexGroup={indexGroup}
            indexFormulario={indexFormulario}
          />
        )}

      </Box>
    ));

    return fieldsLocal;
  };

  const fieldForm = (itemGroup, indexGroup, indexFormulario) => {
    const field = (
      <>
        <Box sx={{
          pl: 0, pr: 0, pt: 2,
        }}
        >
          <Typography
            sx={{
              pt: 0, pb: 0, pl: 2, pr: 2, mb: 0,
            }}
            variant="h6"
            component="p"
          >
            <FeedIcon sx={{ mb: -0.8 }} />
            {' '}
            {itemGroup.title}
          </Typography>
          <Typography variant="p" component="p">
            <Box sx={{ mb: 0, mt: 0 }}>
              <FormControl fullWidth size="small">
                <CardContent sx={{
                  m: 0, pl: 2, pr: 2, pb: 0, pt: 0,
                }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {buildFields(itemGroup.fields, indexGroup, indexFormulario)}
                  </Typography>
                </CardContent>
              </FormControl>
            </Box>
          </Typography>
        </Box>
        <Divider />
      </>
    );

    return field;
  };

  return (
    formulario.length > 0 && formulario.map((groupDates, indexFormulario) => (
      idGroupShow === 0 ? (
        groupDates.initialFields && groupDates.groups.map((itemGroup, indexGroup) => (
          fieldForm(itemGroup, indexGroup, indexFormulario)
        ))) : (groupDates.id === idGroupShow && groupDates.groups.map((itemGroup, indexGroup) => (
        fieldForm(itemGroup, indexGroup, indexFormulario)
      )))
    ))
  );
}

CreateForms.propTypes = {
  fields: PropTypes.objectOf.isRequired,
  getFields: PropTypes.func.isRequired,
  getEnabledForm: PropTypes.func.isRequired,
};

export default CreateForms;
