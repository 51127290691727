import React from 'react';
import {
  TextField,
} from '@mui/material';
import { PropTypes } from 'prop-types';

export function FieldtextArea({
  field, onChange, indexField, indexGroup, indexFormulario,
}) {
  return (
    <TextField
      fullWidth
      size="small"
      label={field.label}
      value={field.value}
      error={field.required && (field.value === '' || field.value === undefined || field.value === null)}
      helperText={field.required && (field.value === '' || field.value === undefined || field.value === null) ? '* Campo Obligatorio' : ''}
      onChange={(e) => { onChange(indexField, e.target.value, indexGroup, indexFormulario); }}
      sx={{ mt: 2 }}
      multiline
      rows={4}
      inputProps={field.length && { maxLength: Number(field.length) }}
    />
  );
}
FieldtextArea.propTypes = {
  field: PropTypes.objectOf.isRequired,
  onChange: PropTypes.func.isRequired,
  indexField: PropTypes.number.isRequired,
  indexGroup: PropTypes.number.isRequired,
  indexFormulario: PropTypes.number.isRequired,
};

export default FieldtextArea;
